<div class="row" style="border-top: 1px solid #eee">
  <div class="col-12 mt-2">
    <kendo-label text="Thumbnail" class="form-title"></kendo-label>
  </div>
</div>

<div class="row">
  <div class="col-12">
    <div *ngIf="this.metadata.thumbnail; else noImage">
      <img [src]="sanitizer.bypassSecurityTrustUrl(this.returnImageFromBase64(this.metadata.thumbnail))" style="max-width: 500px;" />
    </div>
    <ng-template #noImage>
      <h6>No thumbnail provided</h6>
    </ng-template>
  </div>
  <div *ngIf="metadata.canEdit" class="row">
      <div class="col-12">
        <kendo-fileselect  (remove)="removeFile()" (select)="selectFile($event)" [(ngModel)]="myFiles" [restrictions]="fileRestrictions" [multiple]="false"></kendo-fileselect>
    </div>
  </div>
  <div *ngIf="metadata.canEdit" class="row">
    <div class="col-12  mt-2">
      <button kendoButton (click)="removeFile()" icon="trash" style="margin-left:3px;" *ngIf="thumbnailSource">Remove</button>
    </div>
  </div>
</div>
