<div *ngIf="metadata.dataElements" style="width: 100%; height: 100%">
    <ag-grid-angular
     #grid
     class="ag-theme-balham"
     style="width: 100%; height:600px"
     [rowData]="metadata.dataElements"
     [gridOptions]="gridOptions"
     (gridReady)="onGridReady($event)"
     >
   </ag-grid-angular>
</div>