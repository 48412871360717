import {
  Component,
  Input,
  OnInit,
  SecurityContext,
  Output,
  EventEmitter,
} from '@angular/core';
import { Metadata } from 'src/app/models/metadata';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-image-picker',
  templateUrl: './image-picker.component.html',
  styleUrls: ['./image-picker.component.scss'],
})
export class ImagePickerComponent implements OnInit {
  @Output() updateImage = new EventEmitter<any>();
  @Input() metadata: Metadata;

  public myFiles: Array<File>;

  public fileRestrictions: FileRestrictions = {
    allowedExtensions: ['.gif', '.jpg', '.png'],
  };

  public thumbnailSource: string = null;
  constructor(public sanitizer: DomSanitizer) {}

  public selectFile(e: any) {
    const reader = new FileReader();

    if (e.files[0]) {
      const ext = e.files[0].extension;
      const correctExt =
        ext.toUpperCase().includes('JPEG') |
        ext.toUpperCase().includes('PNG') |
        ext.toUpperCase().includes('GIF') |
        ext.toUpperCase().includes('JPG');

      if (correctExt) {
        let self = this;
        reader.readAsDataURL(e.files[0].rawFile);
        reader.onload = function () {
          self.updateImage.emit(reader.result.toString());
          self.thumbnailSource = reader.result.toString();
        };
        this.metadata.formGroup.markAsDirty();
      }
    }
  }

  ngOnInit(): void {
  }

  removeFile() {
    this.updateImage.emit(null);
    this.thumbnailSource = null;
    this.myFiles = null;
  }

  returnImageFromBase64(base64: string): string {
    let returnVal = null;
    let imgType = null;
    if (base64) {
      let baseChar = base64.charAt(0);
      if (baseChar == '/') imgType = 'jpg';
      if (baseChar == 'i') imgType = 'png';
      if (baseChar == 'R') imgType = 'gif';
      if (imgType) {
        returnVal = `data:image/image/${imgType};base64,`.concat(base64);
      }
    }
    return returnVal;
  }

}
