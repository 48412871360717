import { FormGroup, FormControl } from "@angular/forms";
import { DataElement } from "src/app/models/data-element";
import {
  Code,
  CharacterSetCodes,
  ScopeCodes,
  ProgressCodes,
  TopicCategoryCodes,
  MaintenanceFrequencyCodes,
  GeometricObjectTypeCodes,
} from "src/app/models/codes";
import { canOutdentAsListItem } from "@progress/kendo-editor-common";

export class Metadata {
  public layerType: string = null;
  public itemId: string = null;
  public source: string = null;
  public document: Document = null;
  public dataElements: DataElement[] = [];
  public keywords: string[] = [];
  public thumbnail: string = null;
  public canEdit: boolean = false;
  public formGroup: FormGroup = new FormGroup({
    citation: new FormControl(),
    standardName: new FormControl(),
    standardVersion: new FormControl(),
    spatialFeatureType: new FormControl(),
    projection: new FormControl(),
    language: new FormControl(),
    characterSet: new FormControl(),
    scopeLevel: new FormControl(),
    topicCategory: new FormControl(),
    dataStatus: new FormControl(),
    dataUpdateFrequency: new FormControl(),
    creationDate: new FormControl(),
    publicationDate: new FormControl(),
    revisionDate: new FormControl(),
    revisedBy: new FormControl(),
    onlineLinks: new FormControl(),
    westExtent: new FormControl(),
    southExtent: new FormControl(),
    eastExtent: new FormControl(),
    northExtent: new FormControl(),
    temporalExtentStartDate: new FormControl(),
    temporalExtentEndDate: new FormControl(),
    metadataUpdateFrequency: new FormControl(),
    metadataCreatedDate: new FormControl(),
    metadataCreatedTime: new FormControl(),
    metadataLastUpdatedDate: new FormControl(),
    metadataLastUpdatedTime: new FormControl(),
    metadataLastUpdatedBy: new FormControl(),
    metadataNextUpdateDate: new FormControl(),
    abstract: new FormControl(),
    purpose: new FormControl(),
    credits: new FormControl(),
    useConstraints: new FormControl(),
    lineageStatement: new FormControl(),
    processDescription: new FormControl(),
    sourceDescription: new FormControl(),
    maintenanceNotes: new FormControl(),
    rpStewardName: new FormControl(),
    rpOrganization: new FormControl(),
    rpPosition: new FormControl(),
    rpStreet: new FormControl(),
    rpCity: new FormControl(),
    rpState: new FormControl(),
    rpZip: new FormControl(),
    rpEmail: new FormControl(),
    rpPhone: new FormControl(),
    rpFax: new FormControl(),
    rpHours: new FormControl(),
    mcStewardName: new FormControl(),
    mcOrganization: new FormControl(),
    mcPosition: new FormControl(),
    mcStreet: new FormControl(),
    mcCity: new FormControl(),
    mcState: new FormControl(),
    mcZip: new FormControl(),
    mcEmail: new FormControl(),
    mcPhone: new FormControl(),
    mcFax: new FormControl(),
    mcHours: new FormControl(),
    contentStatus: new FormControl(),
  });

  constructor(layerType: string, itemId: string, source: string, canEdit: boolean, xml: string) {
    this.layerType = layerType;
    this.itemId = itemId;
    this.source = source;
    this.canEdit = canEdit;

    this.parseXml(xml);
    this.formGroup.markAsPristine();
  }

  public get esriXml(): string {
    let dataIdInfoNode: Element = null;
    const dataIdInfoNodes: HTMLCollectionOf<Element> = this.document.getElementsByTagName("dataIdInfo");

    if (dataIdInfoNodes && dataIdInfoNodes.length) {
      dataIdInfoNode = dataIdInfoNodes[0];
    } else {
      dataIdInfoNode = this.document.createElement("dataIdInfo");
      this.document.documentElement.appendChild(dataIdInfoNode);
    }

    this.setNodeValue(this.document, ["mdFileID"], this.itemId);
    this.setAttribute(this.document, ["mdLang", "languageCode"], "value", this.formGroup.controls["language"].value);
    this.setAttribute(this.document, ["mdChar", "CharSetCd"], "value", this.formGroup.controls["characterSet"].value);
    this.setAttribute(this.document, ["mdHrLv", "ScopeCd"], "value", this.formGroup.controls["scopeLevel"].value);
    this.setAttribute(this.document, ["dataIdInfo", "resMaint", "maintFreq", "MaintFreqCd"], "value", this.formGroup.controls["dataUpdateFrequency"].value);
    this.setAttribute(this.document, ["spatRepInfo", "VectSpatRep", "geometObjs", "geoObjTyp", "GeoObjTypCd"], "value", this.formGroup.controls["spatialFeatureType"].value);

    this.setNodeValue(this.document, ["Esri", "ArcGISstyle"], this.formGroup.controls["standardName"].value);
    this.setNodeValue(this.document, ["Esri", "ArcGISFormat"], this.formGroup.controls["standardVersion"].value);
    this.setNodeValue(this.document, ["Esri", "DataProperties", "coordRef", "projcsn"], this.formGroup.controls["projection"].value);
    this.setNodeValue(this.document, ["Esri", "CreaDate"], this.convertDateToXmlString(this.formGroup.controls["metadataCreatedDate"].value));
    this.setNodeValue(this.document, ["Esri", "CreaTime"], this.formGroup.controls["metadataCreatedTime"].value);
    this.setNodeValue(this.document, ["Esri", "ModDate"], this.convertDateToXmlString(this.formGroup.controls["metadataLastUpdatedDate"].value));
    this.setNodeValue(this.document, ["Esri", "ModTime"], this.formGroup.controls["metadataLastUpdatedTime"].value);
    this.setNodeValue(this.document, ["Esri", "PublishStatus"], this.formGroup.controls["metadataLastUpdatedBy"].value);

    this.setNodeValue(this.document, ["dataIdInfo", "idCitation", "resTitle"], this.formGroup.controls["citation"].value);
    this.setNodeValue(this.document, ["dataIdInfo", "idAbs"], this.formGroup.controls["abstract"].value);
    this.setNodeValue(this.document, ["dataIdInfo", "idPurp"], this.formGroup.controls["purpose"].value);
    this.setNodeValue(this.document, ["dataIdInfo", "idCredit"], this.formGroup.controls["credits"].value);
    this.setAttribute(this.document, ["dataIdInfo", "dataChar", "CharSetCd"], "value", this.formGroup.controls["characterSet"].value);
    this.setAttribute(this.document, ["dataIdInfo", "tpCat", "TopicCatCd"], "value", this.formGroup.controls["topicCategory"].value);
    this.setAttribute(this.document, ["dataIdInfo", "idStatus", "ProgCd"], "value", this.formGroup.controls["dataStatus"].value);
    this.setNodeValue(this.document, ["dataIdInfo", "idCitation", "date", "createDate"], this.convertDateToXmlString(this.formGroup.controls["creationDate"].value));
    this.setNodeValue(this.document, ["dataIdInfo", "idCitation", "date", "pubDate"], this.convertDateToXmlString(this.formGroup.controls["publicationDate"].value));
    this.setNodeValue(this.document, ["dataIdInfo", "idCitation", "date", "reviseDate"], this.convertDateToXmlString(this.formGroup.controls["revisionDate"].value));
    this.setNodeValue(this.document, ["dataIdInfo", "idCitation", "citRespParty", "rpIndName"], this.formGroup.controls["revisedBy"].value);
    this.setNodeValue(this.document, ["dataIdInfo", "idCitation", "citOnlineRes", "linkage"], this.formGroup.controls["onlineLinks"].value);
    this.setNodeValue(this.document, ["dataIdInfo", "resConst", "Consts", "useLimit"], this.formGroup.controls["useConstraints"].value);
    this.setNodeValue(this.document, ["dataIdInfo", "dataExt", "geoEle", "GeoBndBox", "westBL"], this.formGroup.controls["westExtent"].value);
    this.setNodeValue(this.document, ["dataIdInfo", "dataExt", "geoEle", "GeoBndBox", "southBL"], this.formGroup.controls["southExtent"].value);
    this.setNodeValue(this.document, ["dataIdInfo", "dataExt", "geoEle", "GeoBndBox", "eastBL"], this.formGroup.controls["eastExtent"].value);
    this.setNodeValue(this.document, ["dataIdInfo", "dataExt", "geoEle", "GeoBndBox", "northBL"], this.formGroup.controls["northExtent"].value);
    this.setNodeValue(this.document, ["dataIdInfo", "dataExt", "tempEle", "TempExtent", "exTemp", "TM_Period", "tmBegin"], this.convertDateToXmlString(this.formGroup.controls["temporalExtentStartDate"].value));
    this.setNodeValue(this.document, ["dataIdInfo", "dataExt", "tempEle", "TempExtent", "exTemp", "TM_Period", "tmEnd"], this.convertDateToXmlString(this.formGroup.controls["temporalExtentEndDate"].value));

    this.setNodeValue(this.document, ["dqInfo", "dataLineage", "statement"], this.formGroup.controls["lineageStatement"].value);
    this.setNodeValue(this.document, ["dqInfo", "dataLineage", "prcStep", "stepDesc"], this.formGroup.controls["processDescription"].value);
    this.setNodeValue(this.document, ["dqInfo", "dataLineage", "dataSource", "srcDesc"], this.formGroup.controls["sourceDescription"].value);

    this.setAttribute(this.document, ["mdMaint", "maintFreq", "MaintFreqCd"], "value", this.formGroup.controls["metadataUpdateFrequency"].value);
    this.setNodeValue(this.document, ["mdMaint", "dateNext"], this.convertDateToXmlString(this.formGroup.controls["metadataNextUpdateDate"].value));
    this.setNodeValue(this.document, ["mdMaint", "maintNote"], this.formGroup.controls["maintenanceNotes"].value);

    const contactNodes: HTMLCollectionOf<Element> = this.getMatchingElements(this.document, ["mdContact"], true);
    const responsiblePartyNode: Element = this.getElementByAttribute(this.document, contactNodes, ["role", "RoleCd"], "value", "002", true).parentElement.parentElement;
    const metadataContactNode: Element = this.getElementByAttribute(this.document, contactNodes, ["role", "RoleCd"], "value", "007", true).parentElement.parentElement;

    this.setChildValue(this.document, responsiblePartyNode, ["rpIndName"], this.formGroup.controls["rpStewardName"].value);
    this.setChildValue(this.document, responsiblePartyNode, ["rpOrgName"], this.formGroup.controls["rpOrganization"].value);
    this.setChildValue(this.document, responsiblePartyNode, ["rpPosName"], this.formGroup.controls["rpPosition"].value);
    this.setChildValue(this.document, responsiblePartyNode, ["rpCntInfo", "cntAddress", "delPoint"], this.formGroup.controls["rpStreet"].value);
    this.setChildValue(this.document, responsiblePartyNode, ["rpCntInfo", "cntAddress", "city"], this.formGroup.controls["rpCity"].value);
    this.setChildValue(this.document, responsiblePartyNode, ["rpCntInfo", "cntAddress", "adminArea"], this.formGroup.controls["rpState"].value);
    this.setChildValue(this.document, responsiblePartyNode, ["rpCntInfo", "cntAddress", "postCode"], this.formGroup.controls["rpZip"].value);
    this.setChildValue(this.document, responsiblePartyNode, ["rpCntInfo", "cntAddress", "eMailAdd"], this.formGroup.controls["rpEmail"].value);
    this.setChildValue(this.document, responsiblePartyNode, ["rpCntInfo", "cntPhone", "voiceNum"], this.formGroup.controls["rpPhone"].value);
    this.setChildValue(this.document, responsiblePartyNode, ["rpCntInfo", "cntPhone", "faxNum"], this.formGroup.controls["rpFax"].value);
    this.setChildValue(this.document, responsiblePartyNode, ["rpCntInfo", "cntHours"], this.formGroup.controls["rpHours"].value);

    this.setChildValue(this.document, metadataContactNode, ["rpIndName"], this.formGroup.controls["mcStewardName"].value);
    this.setChildValue(this.document, metadataContactNode, ["rpOrgName"], this.formGroup.controls["mcOrganization"].value);
    this.setChildValue(this.document, metadataContactNode, ["rpPosName"], this.formGroup.controls["mcPosition"].value);
    this.setChildValue(this.document, metadataContactNode, ["rpCntInfo", "cntAddress", "delPoint"], this.formGroup.controls["mcStreet"].value);
    this.setChildValue(this.document, metadataContactNode, ["rpCntInfo", "cntAddress", "city"], this.formGroup.controls["mcCity"].value);
    this.setChildValue(this.document, metadataContactNode, ["rpCntInfo", "cntAddress", "adminArea"], this.formGroup.controls["mcState"].value);
    this.setChildValue(this.document, metadataContactNode, ["rpCntInfo", "cntAddress", "postCode"], this.formGroup.controls["mcZip"].value);
    this.setChildValue(this.document, metadataContactNode, ["rpCntInfo", "cntAddress", "eMailAdd"], this.formGroup.controls["mcEmail"].value);
    this.setChildValue(this.document, metadataContactNode, ["rpCntInfo", "cntPhone", "voiceNum"], this.formGroup.controls["mcPhone"].value);
    this.setChildValue(this.document, metadataContactNode, ["rpCntInfo", "cntPhone", "faxNum"], this.formGroup.controls["mcFax"].value);
    this.setChildValue(this.document, metadataContactNode, ["rpCntInfo", "cntHours"], this.formGroup.controls["mcHours"].value);

    this.setNodeValue(this.document, ["itemInfo", "contentStatus"], this.formGroup.controls["contentStatus"].value);

    this.setEsriXmlKeywords(dataIdInfoNode);
    this.setEsriXmlThumbnail();

    return new XMLSerializer().serializeToString(this.document.documentElement);
  }

  public get isoXml(): string {
    const isoDoc: Document = document.implementation.createDocument("", "MD_Metadata", null);
    isoDoc.documentElement.setAttribute("xmlns:gts", "http://www.isotc211.org/2005/gts");
    isoDoc.documentElement.setAttribute("xmlns:gco", "http://www.isotc211.org/2005/gco");
    isoDoc.documentElement.setAttribute("xmlns:xalan", "http://xml.apache.org/xalan");
    isoDoc.documentElement.setAttribute("xmlns:srv", "http://www.isotc211.org/2005/srv");
    isoDoc.documentElement.setAttribute("xmlns:gmx", "http://www.isotc211.org/2005/gmx");
    isoDoc.documentElement.setAttribute("xmlns:xlink", "http://www.w3.org/1999/xlink");
    isoDoc.documentElement.setAttribute("xmlns:xsi", "http://www.w3.org/2001/XMLSchema-instance");
    isoDoc.documentElement.setAttribute("xmlns:gml", "http://www.opengis.net/gml");
    isoDoc.documentElement.setAttribute("xmlns", "http://www.isotc211.org/2005/gmd");

    this.setNodeValue(isoDoc, ["fileIdentifier", "gco:CharacterString"], this.itemId);

    this.setNodeValue(isoDoc, ["language", "LanguageCode"], this.formGroup.controls["language"].value);
    this.setAttribute(isoDoc, ["language", "LanguageCode"], "codeSpace", "ISO639-2");
    this.setAttribute(isoDoc, ["language", "LanguageCode"], "codeList", "http://www.loc.gov/standards/iso639-2/php/code_list.php");
    this.setAttribute(isoDoc, ["language", "LanguageCode"], "codeListValue", this.formGroup.controls["language"].value);

    const characterSetCode: Code = CharacterSetCodes.find(c => (c.key === this.formGroup.controls["characterSet"].value));
    const characterSetValue: string = (characterSetCode ? characterSetCode.value : null);
    this.setNodeValue(isoDoc, ["characterSet", "MD_CharacterSetCode"], characterSetValue);
    this.setAttribute(isoDoc, ["characterSet", "MD_CharacterSetCode"], "codeSpace", "ISOTC211/19115");
    this.setAttribute(isoDoc, ["characterSet", "MD_CharacterSetCode"], "codeList", "http://www.isotc211.org/2005/resources/Codelist/gmxCodelists.xml#MD_CharacterSetCode");
    this.setAttribute(isoDoc, ["characterSet", "MD_CharacterSetCode"], "codeListValue", characterSetValue);

    const scopeCode: Code = ScopeCodes.find(c => (c.key === this.formGroup.controls["scopeLevel"].value));
    const scopeValue: string = (scopeCode ? scopeCode.value : null);
    this.setNodeValue(isoDoc, ["hierarchyLevel", "MD_ScopeCode"], scopeValue);
    this.setAttribute(isoDoc, ["hierarchyLevel", "MD_ScopeCode"], "codeSpace", "ISOTC211/19115");
    this.setAttribute(isoDoc, ["hierarchyLevel", "MD_ScopeCode"], "codeList", "http://www.isotc211.org/2005/resources/Codelist/gmxCodelists.xml#MD_ScopeCode");
    this.setAttribute(isoDoc, ["hierarchyLevel", "MD_ScopeCode"], "codeListValue", scopeValue);

    this.setNodeValue(isoDoc, ["metadataStandardName", "gco:CharacterString"], "ISO 19139 Geographic Information - Metadata - Implementation Specification");
    this.setNodeValue(isoDoc, ["metadataStandardVersion", "gco:CharacterString"], "2007");
    this.setNodeValue(isoDoc, ["dateStamp", "gco:Date"], this.convertDateToXmlString(this.formGroup.controls["metadataCreatedDate"].value));

    const spatialFeatureTypeCode: Code = GeometricObjectTypeCodes.find(c => (c.key === this.formGroup.controls["spatialFeatureType"].value));
    const spatialFeatureTypeValue: string = (spatialFeatureTypeCode ? spatialFeatureTypeCode.value : null);
    this.setNodeValue(isoDoc, ["spatialRepresentationInfo", "MD_VectorSpatialRepresentation", "geometricObjects", "MD_GeometricObjects", "geometricObjectType", "MD_GeometricObjectTypeCode"], spatialFeatureTypeValue);
    this.setAttribute(isoDoc, ["spatialRepresentationInfo", "MD_VectorSpatialRepresentation", "geometricObjects", "MD_GeometricObjects", "geometricObjectType", "MD_GeometricObjectTypeCode"], "codeSpace", "ISOTC211/19115");
    this.setAttribute(isoDoc, ["spatialRepresentationInfo", "MD_VectorSpatialRepresentation", "geometricObjects", "MD_GeometricObjects", "geometricObjectType", "MD_GeometricObjectTypeCode"], "codeList", "http://www.isotc211.org/2005/resources/Codelist/gmxCodelists.xml#MD_GeometricObjectTypeCode");
    this.setAttribute(isoDoc, ["spatialRepresentationInfo", "MD_VectorSpatialRepresentation", "geometricObjects", "MD_GeometricObjects", "geometricObjectType", "MD_GeometricObjectTypeCode"], "codeListValue", spatialFeatureTypeValue);

    let identificationInfoNode: Element = null;
    const identificationInfoNodes: HTMLCollectionOf<Element> = isoDoc.getElementsByTagName("identificationInfo");

    if (identificationInfoNodes && identificationInfoNodes.length) {
      identificationInfoNode = identificationInfoNodes[0];
    } else {
      identificationInfoNode = isoDoc.createElement("identificationInfo");
      isoDoc.documentElement.appendChild(identificationInfoNode);
    }

    this.setNodeValue(isoDoc, ["identificationInfo", "MD_DataIdentification", "citation", "CI_Citation", "title", "gco:CharacterString"], this.formGroup.controls["citation"].value);
    this.setNodeValue(isoDoc, ["identificationInfo", "MD_DataIdentification", "abstract", "gco:CharacterString"], this.formGroup.controls["abstract"].value);
    this.setNodeValue(isoDoc, ["identificationInfo", "MD_DataIdentification", "purpose", "gco:CharacterString"], this.formGroup.controls["purpose"].value);
    this.setNodeValue(isoDoc, ["identificationInfo", "MD_DataIdentification", "credit", "gco:CharacterString"], this.formGroup.controls["credits"].value);
    this.setNodeValue(isoDoc, ["identificationInfo", "MD_DataIdentification", "resourceConstraints", "MD_Constraints", "useLimitation", "gco:CharacterString"], this.formGroup.controls["useConstraints"].value);

    const topicCategoryCode: Code = TopicCategoryCodes.find(c => (c.key === this.formGroup.controls["topicCategory"].value));
    const topicCategoryValue: string = (topicCategoryCode ? topicCategoryCode.value : null);
    this.setNodeValue(isoDoc, ["identificationInfo", "MD_DataIdentification", "topicCategory", "MD_TopicCategoryCode"], topicCategoryValue);

    const progressCode: Code = ProgressCodes.find(c => (c.key === this.formGroup.controls["dataStatus"].value));
    const progressValue: string = (progressCode ? progressCode.value : null);
    this.setNodeValue(isoDoc, ["identificationInfo", "MD_DataIdentification", "status", "MD_ProgressCode"], progressValue);
    this.setAttribute(isoDoc, ["identificationInfo", "MD_DataIdentification", "status", "MD_ProgressCode"], "codeSpace", "ISOTC211/19115");
    this.setAttribute(isoDoc, ["identificationInfo", "MD_DataIdentification", "status", "MD_ProgressCode"], "codeList", "http://www.isotc211.org/2005/resources/Codelist/gmxCodelists.xml#MD_ProgressCode");
    this.setAttribute(isoDoc, ["identificationInfo", "MD_DataIdentification", "status", "MD_ProgressCode"], "codeListValue", progressValue);

    const citationNode: Element = this.getElement(isoDoc, ["identificationInfo", "MD_DataIdentification", "citation", "CI_Citation"]);
    const creationDateNode: Element = citationNode.appendChild(isoDoc.createElement("date"));
    const publicationDateNode: Element = citationNode.appendChild(isoDoc.createElement("date"));
    const revisionDateNode: Element = citationNode.appendChild(isoDoc.createElement("date"));

    this.setChildValue(isoDoc, creationDateNode, ["CI_Date", "date", "gco:Date"], this.convertDateToXmlString(this.formGroup.controls["creationDate"].value));
    this.setChildValue(isoDoc, creationDateNode, ["CI_Date", "dateType", "CI_DateTypeCode"], "creation");
    this.setChildAttribute(isoDoc, creationDateNode, ["CI_Date", "dateType", "CI_DateTypeCode"], "codeSpace", "ISOTC211/19115");
    this.setChildAttribute(isoDoc, creationDateNode, ["CI_Date", "dateType", "CI_DateTypeCode"], "codeList", "http://www.isotc211.org/2005/resources/Codelist/gmxCodelists.xml#CI_DateTypeCode");
    this.setChildAttribute(isoDoc, creationDateNode, ["CI_Date", "dateType", "CI_DateTypeCode"], "codeListValue", "creation");

    this.setChildValue(isoDoc, publicationDateNode, ["CI_Date", "date", "gco:Date"], this.convertDateToXmlString(this.formGroup.controls["publicationDate"].value));
    this.setChildValue(isoDoc, publicationDateNode, ["CI_Date", "dateType", "CI_DateTypeCode"], "publication");
    this.setChildAttribute(isoDoc, creationDateNode, ["CI_Date", "dateType", "CI_DateTypeCode"], "codeSpace", "ISOTC211/19115");
    this.setChildAttribute(isoDoc, publicationDateNode, ["CI_Date", "dateType", "CI_DateTypeCode"], "codeList", "http://www.isotc211.org/2005/resources/Codelist/gmxCodelists.xml#CI_DateTypeCode");
    this.setChildAttribute(isoDoc, publicationDateNode, ["CI_Date", "dateType", "CI_DateTypeCode"], "codeListValue", "publication");

    this.setChildValue(isoDoc, revisionDateNode, ["CI_Date", "date", "gco:Date"], this.convertDateToXmlString(this.formGroup.controls["revisionDate"].value));
    this.setChildValue(isoDoc, revisionDateNode, ["CI_Date", "dateType", "CI_DateTypeCode"], "revision");
    this.setChildAttribute(isoDoc, creationDateNode, ["CI_Date", "dateType", "CI_DateTypeCode"], "codeSpace", "ISOTC211/19115");
    this.setChildAttribute(isoDoc, revisionDateNode, ["CI_Date", "dateType", "CI_DateTypeCode"], "codeList", "http://www.isotc211.org/2005/resources/Codelist/gmxCodelists.xml#CI_DateTypeCode");
    this.setChildAttribute(isoDoc, revisionDateNode, ["CI_Date", "dateType", "CI_DateTypeCode"], "codeListValue", "revision");

    this.setNodeValue(isoDoc, ["identificationInfo", "MD_DataIdentification", "citation", "CI_Citation", "citedResponsibleParty", "CI_ResponsibleParty", "individualName", "gco:CharacterString"], this.formGroup.controls["revisedBy"].value);
    this.setNodeValue(isoDoc, ["identificationInfo", "MD_DataIdentification", "citation", "CI_Citation", "citedResponsibleParty", "CI_ResponsibleParty", "role", "CI_RoleCode"], "pointOfContact");
    this.setAttribute(isoDoc, ["identificationInfo", "MD_DataIdentification", "citation", "CI_Citation", "citedResponsibleParty", "CI_ResponsibleParty", "role", "CI_RoleCode"], "codeSpace", "ISOTC211/19115");
    this.setAttribute(isoDoc, ["identificationInfo", "MD_DataIdentification", "citation", "CI_Citation", "citedResponsibleParty", "CI_ResponsibleParty", "role", "CI_RoleCode"], "codeList", "http://www.isotc211.org/2005/resources/Codelist/gmxCodelists.xml#CI_RoleCode");
    this.setAttribute(isoDoc, ["identificationInfo", "MD_DataIdentification", "citation", "CI_Citation", "citedResponsibleParty", "CI_ResponsibleParty", "role", "CI_RoleCode"], "codeListValue", "pointOfContact");

    this.setNodeValue(isoDoc, ["identificationInfo", "MD_DataIdentification", "extent", "EX_Extent", "geographicElement", "EX_GeographicBoundingBox", "westBoundLongitude", "gco:Decimal"], this.formGroup.controls["westExtent"].value);
    this.setNodeValue(isoDoc, ["identificationInfo", "MD_DataIdentification", "extent", "EX_Extent", "geographicElement", "EX_GeographicBoundingBox", "southBoundLongitude", "gco:Decimal"], this.formGroup.controls["southExtent"].value);
    this.setNodeValue(isoDoc, ["identificationInfo", "MD_DataIdentification", "extent", "EX_Extent", "geographicElement", "EX_GeographicBoundingBox", "eastBoundLongitude", "gco:Decimal"], this.formGroup.controls["eastExtent"].value);
    this.setNodeValue(isoDoc, ["identificationInfo", "MD_DataIdentification", "extent", "EX_Extent", "geographicElement", "EX_GeographicBoundingBox", "northBoundLongitude", "gco:Decimal"], this.formGroup.controls["northExtent"].value);

    this.setNodeValue(isoDoc, ["identificationInfo", "MD_DataIdentification", "extent", "EX_Extent", "temporalElement", "EX_TemporalExtent", "extent", "gml:TimePeriod", "gml:beginPosition"], this.formGroup.controls["temporalExtentStartDate"].value);
    this.setNodeValue(isoDoc, ["identificationInfo", "MD_DataIdentification", "extent", "EX_Extent", "temporalElement", "EX_TemporalExtent", "extent", "gml:TimePeriod", "gml:endPosition"], this.formGroup.controls["temporalExtentEndDate"].value);

    const dataUpdateFrequencyCode: Code = MaintenanceFrequencyCodes.find(c => (c.key === this.formGroup.controls["dataUpdateFrequency"].value));
    const dataUpdateFrequencyValue: string = (dataUpdateFrequencyCode ? dataUpdateFrequencyCode.value : null);
    this.setNodeValue(isoDoc, ["identificationInfo", "MD_DataIdentification", "resourceMaintenance", "MD_MaintenanceInformation", "maintenanceAndUpdateFrequency", "MD_MaintenanceFrequencyCode"], dataUpdateFrequencyValue);
    this.setAttribute(isoDoc, ["identificationInfo", "MD_DataIdentification", "resourceMaintenance", "MD_MaintenanceInformation", "maintenanceAndUpdateFrequency", "MD_MaintenanceFrequencyCode"], "codeSpace", "ISOTC211/19115");
    this.setAttribute(isoDoc, ["identificationInfo", "MD_DataIdentification", "resourceMaintenance", "MD_MaintenanceInformation", "maintenanceAndUpdateFrequency", "MD_MaintenanceFrequencyCode"], "codeList", "http://www.isotc211.org/2005/resources/Codelist/gmxCodelists.xml#MD_MaintenanceFrequencyCode");
    this.setAttribute(isoDoc, ["identificationInfo", "MD_DataIdentification", "resourceMaintenance", "MD_MaintenanceInformation", "maintenanceAndUpdateFrequency", "MD_MaintenanceFrequencyCode"], "codeListValue", dataUpdateFrequencyValue);

    this.setNodeValue(isoDoc, ["dataQualityInfo", "DQ_DataQuality", "lineage", "LI_Lineage", "statement", "gco:CharacterString"], this.formGroup.controls["lineageStatement"].value);
    this.setNodeValue(isoDoc, ["dataQualityInfo", "DQ_DataQuality", "lineage", "LI_Lineage", "processStep", "LI_ProcessStep", "description", "gco:CharacterString"], this.formGroup.controls["processDescription"].value);
    this.setNodeValue(isoDoc, ["dataQualityInfo", "DQ_DataQuality", "lineage", "LI_Lineage", "source", "LI_Source", "description", "gco:CharacterString"], this.formGroup.controls["sourceDescription"].value);

    const metadataUpdateFrequencyCode: Code = MaintenanceFrequencyCodes.find(c => (c.key === this.formGroup.controls["metadataUpdateFrequency"].value));
    const metadataUpdateFrequencyValue: string = (metadataUpdateFrequencyCode ? metadataUpdateFrequencyCode.value : null);
    this.setNodeValue(isoDoc, ["metadataMaintenance", "MD_MaintenanceInformation", "maintenanceAndUpdateFrequency", "MD_MaintenanceFrequencyCode"], metadataUpdateFrequencyValue);
    this.setAttribute(isoDoc, ["metadataMaintenance", "MD_MaintenanceInformation", "maintenanceAndUpdateFrequency", "MD_MaintenanceFrequencyCode"], "codeSpace", "ISOTC211/19115");
    this.setAttribute(isoDoc, ["metadataMaintenance", "MD_MaintenanceInformation", "maintenanceAndUpdateFrequency", "MD_MaintenanceFrequencyCode"], "codeList", "http://www.isotc211.org/2005/resources/Codelist/gmxCodelists.xml#MD_MaintenanceFrequencyCode");
    this.setAttribute(isoDoc, ["metadataMaintenance", "MD_MaintenanceInformation", "maintenanceAndUpdateFrequency", "MD_MaintenanceFrequencyCode"], "codeListValue", metadataUpdateFrequencyValue);

    this.setNodeValue(isoDoc, ["metadataMaintenance", "MD_MaintenanceInformation", "dateOfNextUpdate", "gco:Date"], this.convertDateToXmlString(this.formGroup.controls["metadataNextUpdateDate"].value));
    this.setNodeValue(isoDoc, ["metadataMaintenance", "MD_MaintenanceInformation", "maintenanceNote", "gco:CharacterString"], this.formGroup.controls["maintenanceNotes"].value);

    const custodianRoleNode: Element = this.getElementByNodeValue(isoDoc, ["contact", "CI_ResponsibleParty", "role", "CI_RoleCode"], "custodian");
    this.setChildAttribute(isoDoc, custodianRoleNode, null, "codeSpace", "ISOTC211/19115");
    this.setChildAttribute(isoDoc, custodianRoleNode, null, "codeListValue", "custodian");
    this.setChildAttribute(isoDoc, custodianRoleNode, null, "codeList", "http://www.isotc211.org/2005/resources/Codelist/gmxCodelists.xml#CI_RoleCode");

    const responsiblePartyNode: Element = custodianRoleNode.parentElement.parentElement;
    this.setChildValue(isoDoc, responsiblePartyNode, ["individualName", "gco:CharacterString"], this.formGroup.controls["rpStewardName"].value);
    this.setChildValue(isoDoc, responsiblePartyNode, ["organisationName", "gco:CharacterString"], this.formGroup.controls["rpOrganization"].value);
    this.setChildValue(isoDoc, responsiblePartyNode, ["positionName", "gco:CharacterString"], this.formGroup.controls["rpPosition"].value);
    this.setChildValue(isoDoc, responsiblePartyNode, ["contactInfo", "CI_Contact", "address", "CI_Address", "deliveryPoint", "gco:CharacterString"], this.formGroup.controls["rpStreet"].value);
    this.setChildValue(isoDoc, responsiblePartyNode, ["contactInfo", "CI_Contact", "address", "CI_Address", "city", "gco:CharacterString"], this.formGroup.controls["rpCity"].value);
    this.setChildValue(isoDoc, responsiblePartyNode, ["contactInfo", "CI_Contact", "address", "CI_Address", "administrativeArea", "gco:CharacterString"], this.formGroup.controls["rpState"].value);
    this.setChildValue(isoDoc, responsiblePartyNode, ["contactInfo", "CI_Contact", "address", "CI_Address", "postalCode", "gco:CharacterString"], this.formGroup.controls["rpZip"].value);
    this.setChildValue(isoDoc, responsiblePartyNode, ["contactInfo", "CI_Contact", "address", "CI_Address", "country", "gco:CharacterString"], "US");
    this.setChildValue(isoDoc, responsiblePartyNode, ["contactInfo", "CI_Contact", "address", "CI_Address", "electronicMailAddress", "gco:CharacterString"], this.formGroup.controls["rpEmail"].value);
    this.setChildValue(isoDoc, responsiblePartyNode, ["contactInfo", "CI_Contact", "phone", "CI_Telephone", "voice", "gco:CharacterString"], this.formGroup.controls["rpPhone"].value);
    this.setChildValue(isoDoc, responsiblePartyNode, ["contactInfo", "CI_Contact", "phone", "CI_Telephone", "facsimile", "gco:CharacterString"], this.formGroup.controls["rpFax"].value);
    this.setChildValue(isoDoc, responsiblePartyNode, ["contactInfo", "CI_Contact", "hoursOfService", "gco:CharacterString"], this.formGroup.controls["rpHours"].value);

    const pointOfContactRoleNode: Element = this.getElementByNodeValue(isoDoc, ["contact", "CI_ResponsibleParty", "role", "CI_RoleCode"], "pointOfContact");
    this.setChildAttribute(isoDoc, pointOfContactRoleNode, null, "codeSpace", "ISOTC211/19115");
    this.setChildAttribute(isoDoc, pointOfContactRoleNode, null, "codeListValue", "pointOfContact");
    this.setChildAttribute(isoDoc, pointOfContactRoleNode, null, "codeList", "http://www.isotc211.org/2005/resources/Codelist/gmxCodelists.xml#CI_RoleCode");

    const metadataContactNode: Element = pointOfContactRoleNode.parentElement.parentElement;
    this.setChildValue(isoDoc, metadataContactNode, ["individualName", "gco:CharacterString"], this.formGroup.controls["mcStewardName"].value);
    this.setChildValue(isoDoc, metadataContactNode, ["organisationName", "gco:CharacterString"], this.formGroup.controls["mcOrganization"].value);
    this.setChildValue(isoDoc, metadataContactNode, ["positionName", "gco:CharacterString"], this.formGroup.controls["mcPosition"].value);
    this.setChildValue(isoDoc, metadataContactNode, ["contactInfo", "CI_Contact", "address", "CI_Address", "deliveryPoint", "gco:CharacterString"], this.formGroup.controls["mcStreet"].value);
    this.setChildValue(isoDoc, metadataContactNode, ["contactInfo", "CI_Contact", "address", "CI_Address", "city", "gco:CharacterString"], this.formGroup.controls["mcCity"].value);
    this.setChildValue(isoDoc, metadataContactNode, ["contactInfo", "CI_Contact", "address", "CI_Address", "administrativeArea", "gco:CharacterString"], this.formGroup.controls["mcState"].value);
    this.setChildValue(isoDoc, metadataContactNode, ["contactInfo", "CI_Contact", "address", "CI_Address", "postalCode", "gco:CharacterString"], this.formGroup.controls["mcZip"].value);
    this.setChildValue(isoDoc, metadataContactNode, ["contactInfo", "CI_Contact", "address", "CI_Address", "country", "gco:CharacterString"], "US");
    this.setChildValue(isoDoc, metadataContactNode, ["contactInfo", "CI_Contact", "address", "CI_Address", "electronicMailAddress", "gco:CharacterString"], this.formGroup.controls["mcEmail"].value);
    this.setChildValue(isoDoc, metadataContactNode, ["contactInfo", "CI_Contact", "phone", "CI_Telephone", "voice", "gco:CharacterString"], this.formGroup.controls["mcPhone"].value);
    this.setChildValue(isoDoc, metadataContactNode, ["contactInfo", "CI_Contact", "phone", "CI_Telephone", "facsimile", "gco:CharacterString"], this.formGroup.controls["mcFax"].value);
    this.setChildValue(isoDoc, metadataContactNode, ["contactInfo", "CI_Contact", "hoursOfService", "gco:CharacterString"], this.formGroup.controls["mcHours"].value);

    this.setIsoXmlKeywords(isoDoc, identificationInfoNode);

    return new XMLSerializer().serializeToString(isoDoc.documentElement);
  }

  public parseXml(xml: string): void {
    const parser: DOMParser = new DOMParser();
    this.document = parser.parseFromString(xml, "text/xml");

    this.getMetadataOverview();
    this.getResponsiblePartySteward();
    this.getMetadataContactSteward();
    this.getDataElements();
    this.getKeywords();
    this.getThumbnail();
    this.getItemInfo();

    this.formGroup.markAsDirty();
  }

  private getMetadataOverview(): void {
    this.formGroup.controls["language"].setValue(this.getAttributeValueByPath(["mdLang", "languageCode"]));
    this.formGroup.controls["characterSet"].setValue(this.getAttributeValueByPath(["mdChar", "CharSetCd"]));
    this.formGroup.controls["scopeLevel"].setValue(this.getAttributeValueByPath(["mdHrLv", "ScopeCd"]));
    this.formGroup.controls["dataUpdateFrequency"].setValue(this.getAttributeValueByPath(["dataIdInfo", "resMaint", "maintFreq", "MaintFreqCd"]));
    this.formGroup.controls["spatialFeatureType"].setValue(this.getAttributeValueByPath(["spatRepInfo", "VectSpatRep", "geometObjs", "geoObjTyp", "GeoObjTypCd"]));

    this.formGroup.controls["standardName"].setValue(this.getNodeValueByPath(["Esri", "ArcGISstyle"]));
    if (!this.formGroup.controls["standardName"].value) {
      this.formGroup.controls["standardName"].setValue("ISO 19139 Metadata Implementation Specification GML3.2");
    }

    this.formGroup.controls["standardVersion"].setValue(this.getNodeValueByPath(["Esri", "ArcGISFormat"]));
    if (!this.formGroup.controls["standardVersion"].value) {
      this.formGroup.controls["standardVersion"].setValue("1.0");
    }

    this.formGroup.controls["projection"].setValue(this.getNodeValueByPath(["Esri", "DataProperties", "coordRef", "projcsn"]));
    this.formGroup.controls["metadataCreatedDate"].setValue(this.convertXmlStringToDate(this.getNodeValueByPath(["Esri", "CreaDate"])));
    this.formGroup.controls["metadataCreatedTime"].setValue(this.getNodeValueByPath(["Esri", "CreaTime"]));
    this.formGroup.controls["metadataLastUpdatedDate"].setValue(this.convertXmlStringToDate(this.getNodeValueByPath(["Esri", "ModDate"])));
    this.formGroup.controls["metadataLastUpdatedTime"].setValue(this.getNodeValueByPath(["Esri", "ModTime"]));
    this.formGroup.controls["metadataLastUpdatedBy"].setValue(this.getNodeValueByPath(["Esri", "PublishStatus"]));

    this.formGroup.controls["citation"].setValue(this.getNodeValueByPath(["dataIdInfo", "idCitation", "resTitle"]));
    this.formGroup.controls["abstract"].setValue(this.getNodeValueByPath(["dataIdInfo", "idAbs"]));
    this.formGroup.controls["purpose"].setValue(this.getNodeValueByPath(["dataIdInfo", "idPurp"]));
    this.formGroup.controls["credits"].setValue(this.getNodeValueByPath(["dataIdInfo", "idCredit"]));
    this.formGroup.controls["topicCategory"].setValue(this.getAttributeValueByPath(["dataIdInfo", "tpCat", "TopicCatCd"]));
    this.formGroup.controls["dataStatus"].setValue(this.getAttributeValueByPath(["dataIdInfo", "idStatus", "ProgCd"]));
    this.formGroup.controls["creationDate"].setValue(this.convertXmlStringToDate(this.getNodeValueByPath(["dataIdInfo", "idCitation", "date", "createDate"])));
    this.formGroup.controls["publicationDate"].setValue(this.convertXmlStringToDate(this.getNodeValueByPath(["dataIdInfo", "idCitation", "date", "pubDate"])));
    this.formGroup.controls["revisionDate"].setValue(this.convertXmlStringToDate(this.getNodeValueByPath(["dataIdInfo", "idCitation", "date", "reviseDate"])));
    this.formGroup.controls["revisedBy"].setValue(this.getNodeValueByPath(["dataIdInfo", "idCitation", "citRespParty", "rpIndName"]));
    this.formGroup.controls["onlineLinks"].setValue(this.getNodeValueByPath(["dataIdInfo", "idCitation", "citOnlineRes", "linkage"]));
    this.formGroup.controls["useConstraints"].setValue(this.getNodeValueByPath(["dataIdInfo", "resConst", "Consts", "useLimit"]));
    this.formGroup.controls["westExtent"].setValue(this.getNodeValueByPath(["dataIdInfo", "dataExt", "geoEle", "GeoBndBox", "westBL"]));
    this.formGroup.controls["southExtent"].setValue(this.getNodeValueByPath(["dataIdInfo", "dataExt", "geoEle", "GeoBndBox", "southBL"]));
    this.formGroup.controls["eastExtent"].setValue(this.getNodeValueByPath(["dataIdInfo", "dataExt", "geoEle", "GeoBndBox", "eastBL"]));
    this.formGroup.controls["northExtent"].setValue(this.getNodeValueByPath(["dataIdInfo", "dataExt", "geoEle", "GeoBndBox", "northBL"]));
    this.formGroup.controls["temporalExtentStartDate"].setValue(this.convertXmlStringToDate(this.getNodeValueByPath(["dataIdInfo", "dataExt", "tempEle", "TempExtent", "exTemp", "TM_Period", "tmBegin"])));
    this.formGroup.controls["temporalExtentEndDate"].setValue(this.convertXmlStringToDate(this.getNodeValueByPath(["dataIdInfo", "dataExt", "tempEle", "TempExtent", "exTemp", "TM_Period", "tmEnd"])));

    this.formGroup.controls["lineageStatement"].setValue(this.getNodeValueByPath(["dqInfo", "dataLineage", "statement"]));
    this.formGroup.controls["processDescription"].setValue(this.getNodeValueByPath(["dqInfo", "dataLineage", "prcStep", "stepDesc"]));
    this.formGroup.controls["sourceDescription"].setValue(this.getNodeValueByPath(["dqInfo", "dataLineage", "dataSource", "srcDesc"]));

    this.formGroup.controls["metadataUpdateFrequency"].setValue(this.getAttributeValueByPath(["mdMaint", "maintFreq", "MaintFreqCd"]));
    this.formGroup.controls["metadataNextUpdateDate"].setValue(this.convertXmlStringToDate(this.getNodeValueByPath(["mdMaint", "dateNext"])));
    this.formGroup.controls["maintenanceNotes"].setValue(this.getNodeValueByPath(["mdMaint", "maintNote"]));
  }

  private getResponsiblePartySteward(): void {
    this.getSteward("rp", 2);
  }

  private getMetadataContactSteward(): void {
    this.getSteward("mc", 7);
  }

  private getSteward(prefix: string, code: number): void {
    let contactNodes: HTMLCollectionOf<Element> = this.getMatchingElements(this.document, ["mdContact"], false);

    if (contactNodes && contactNodes.length) {
      const roleNode: Element = this.getElementByAttribute(this.document, contactNodes, ["role", "RoleCd"], "value", String(code).padStart(3, "0"), false);
      let contactNode: Element = null;

      if (roleNode) {
        contactNode = roleNode.parentElement.parentElement;
      }

      if (!contactNode) {
        contactNode = this.fixupContact(prefix, code, contactNodes);
      }

      if (contactNode) {
        this.formGroup.controls[`${prefix}StewardName`].setValue(this.getChildNodeValue(contactNode, "rpIndName"));
        this.formGroup.controls[`${prefix}Organization`].setValue(this.getChildNodeValue(contactNode, "rpOrgName"));
        this.formGroup.controls[`${prefix}Position`].setValue(this.getChildNodeValue(contactNode, "rpPosName"));
  
        const contactInfoNode: Element = this.getChildNode(this.document, contactNode, "rpCntInfo", false);
        if (contactInfoNode) {
          this.formGroup.controls[`${prefix}Hours`].setValue(this.getChildNodeValue(contactInfoNode, "cntHours"));
  
          const contactPhoneNode: Element = this.getChildNode(this.document, contactInfoNode, "cntPhone", false);
          if (contactPhoneNode) {
            this.formGroup.controls[`${prefix}Phone`].setValue(this.getChildNodeValue(contactPhoneNode, "voiceNum"));
            this.formGroup.controls[`${prefix}Fax`].setValue(this.getChildNodeValue(contactPhoneNode, "faxNum"));
          }
  
          const contactAddressNode: Element = this.getChildNode(this.document, contactInfoNode, "cntAddress", false);
          if (contactAddressNode) {
            this.formGroup.controls[`${prefix}Street`].setValue(this.getChildNodeValue(contactAddressNode, "delPoint"));
            this.formGroup.controls[`${prefix}City`].setValue(this.getChildNodeValue(contactAddressNode, "city"));
            this.formGroup.controls[`${prefix}State`].setValue(this.getChildNodeValue(contactAddressNode, "adminArea"));
            this.formGroup.controls[`${prefix}Zip`].setValue(this.getChildNodeValue(contactAddressNode, "postCode"));
            this.formGroup.controls[`${prefix}Email`].setValue(this.getChildNodeValue(contactAddressNode, "eMailAdd"));
          }
        }
      }
    }
  }

  private fixupContact(prefix: string, code: number, contactNodes: HTMLCollectionOf<Element>): Element {
    let contactNode: Element = null;

    if ((prefix === "rp") && (contactNodes != null) && (contactNodes.length === 1)) {
      contactNode = contactNodes[0];
      const roleNodes: HTMLCollectionOf<Element> = contactNode.getElementsByTagName("role");

      if (!roleNodes || !roleNodes.length) {
        const roleNode: Element = contactNode.appendChild(this.document.createElement("role"));
        const roleCdNode: Element = roleNode.appendChild(this.document.createElement("RoleCd"));
        roleCdNode.setAttribute("value", String(code).padStart(3, "0"));
      }
    } else if (prefix === "mc") {
      const mdMaintNodes: HTMLCollectionOf<Element> = this.document.getElementsByTagName("mdMaint");

      if (mdMaintNodes && mdMaintNodes.length) {
        const mdMaintContactNodes: HTMLCollectionOf<Element> = mdMaintNodes[0].getElementsByTagName("contact");

        if (mdMaintContactNodes && mdMaintContactNodes.length) {
          contactNode = this.document.documentElement.appendChild(this.document.createElement("mdContact"));

          const roleNode: Element = contactNode.appendChild(this.document.createElement("role"));
          const roleCdNode: Element = roleNode.appendChild(this.document.createElement("RoleCd"));
          roleCdNode.setAttribute("value", String(code).padStart(3, "0"));

          const rpIndName: Element = contactNode.appendChild(this.document.createElement("rpIndName"));
          rpIndName.textContent = this.getChildNodeValue(mdMaintContactNodes[0], "rpIndName");

          const rpOrgName: Element = contactNode.appendChild(this.document.createElement("rpOrgName"));
          rpOrgName.textContent = this.getChildNodeValue(mdMaintContactNodes[0], "rpOrgName");

          const rpPosName: Element = contactNode.appendChild(this.document.createElement("rpPosName"));
          rpPosName.textContent = this.getChildNodeValue(mdMaintContactNodes[0], "rpPosName");

          const mdMaintContactInfoNodes: HTMLCollectionOf<Element> = mdMaintContactNodes[0].getElementsByTagName("rpCntInfo");
          if (mdMaintContactInfoNodes && mdMaintContactInfoNodes.length) {
            const contactInfoNode: Element = contactNode.appendChild(this.document.createElement("rpCntInfo"));

            const mdMaintContactPhoneNodes: HTMLCollectionOf<Element> = mdMaintContactInfoNodes[0].getElementsByTagName("cntPhone");
            if (mdMaintContactPhoneNodes && mdMaintContactPhoneNodes.length) {
              const contactPhoneNode: Element = contactInfoNode.appendChild(this.document.createElement("cntPhone"));

              const voiceNum: Element = contactPhoneNode.appendChild(this.document.createElement("voiceNum"));
              voiceNum.textContent = this.getChildNodeValue(mdMaintContactPhoneNodes[0], "voiceNum");

              const faxNum: Element = contactPhoneNode.appendChild(this.document.createElement("faxNum"));
              faxNum.textContent = this.getChildNodeValue(mdMaintContactPhoneNodes[0], "faxNum");
            }
  
            const mdMaintContactAddressNodes: HTMLCollectionOf<Element> = mdMaintContactInfoNodes[0].getElementsByTagName("cntAddress");
            if (mdMaintContactAddressNodes && mdMaintContactAddressNodes.length) {
              const contactAddressNode: Element = contactInfoNode.appendChild(this.document.createElement("cntAddress"));

              const delPoint: Element = contactAddressNode.appendChild(this.document.createElement("delPoint"));
              delPoint.textContent = this.getChildNodeValue(mdMaintContactAddressNodes[0], "delPoint");

              const city: Element = contactAddressNode.appendChild(this.document.createElement("city"));
              city.textContent = this.getChildNodeValue(mdMaintContactAddressNodes[0], "city");

              const adminArea: Element = contactAddressNode.appendChild(this.document.createElement("adminArea"));
              adminArea.textContent = this.getChildNodeValue(mdMaintContactAddressNodes[0], "adminArea");

              const postCode: Element = contactAddressNode.appendChild(this.document.createElement("postCode"));
              postCode.textContent = this.getChildNodeValue(mdMaintContactAddressNodes[0], "postCode");

              const country: Element = contactAddressNode.appendChild(this.document.createElement("country"));
              country.textContent = this.getChildNodeValue(mdMaintContactAddressNodes[0], "country");

              const eMailAdd: Element = contactAddressNode.appendChild(this.document.createElement("eMailAdd"));
              eMailAdd.textContent = this.getChildNodeValue(mdMaintContactAddressNodes[0], "eMailAdd");
            }

            const cntHours: Element = contactInfoNode.appendChild(this.document.createElement("cntHours"));
            cntHours.textContent = this.getChildNodeValue(mdMaintContactInfoNodes[0], "cntHours");

            const cntInstr: Element = contactInfoNode.appendChild(this.document.createElement("cntInstr"));
            cntInstr.textContent = this.getChildNodeValue(mdMaintContactInfoNodes[0], "cntInstr");
          }

          mdMaintNodes[0].removeChild(mdMaintContactNodes[0]);
        }
      }
    }

    return contactNode;
  }

  private getDataElements(): void {
    const detailedInfoNode: Element = this.getElement(this.document, ["eainfo", "detailed"]);
    if (detailedInfoNode) {
      const attributeNodes: HTMLCollectionOf<Element> = detailedInfoNode.getElementsByTagName("attr");
      if (attributeNodes && attributeNodes.length) {
        for (let i = 0; i < attributeNodes.length; i++) {
          const domvNodes: HTMLCollectionOf<Element> = attributeNodes[i].getElementsByTagName("attrdomv");
          const elem: DataElement = {
            label: this.getInnerHtml(attributeNodes[i], "attrlabl"),
            alias: this.getInnerHtml(attributeNodes[i], "attalias"),
            type: this.getInnerHtml(attributeNodes[i], "attrtype"),
            width: this.getInnerHtml(attributeNodes[i], "attwidth"),
            precision: this.getInnerHtml(attributeNodes[i], "atprecis"),
            scale: this.getInnerHtml(attributeNodes[i], "attscale"),
            notes: ((domvNodes && domvNodes.length) ? this.getInnerHtml(domvNodes[0], "udom") : null)
          };
          this.dataElements.push(elem);
        }
      }
    }
  }

  private getKeywords(): void {
    this.keywords = [];
    const searchKeysNode: Element = this.getElement(this.document, ["dataIdInfo", "searchKeys"]);

    if (searchKeysNode) {
      const keywordsNode: HTMLCollectionOf<Element> = searchKeysNode.getElementsByTagName("keyword");
      if (keywordsNode) {
        for (let i = 0; i < keywordsNode.length; i++) {
          this.keywords.push(keywordsNode[i].innerHTML);
        }
      }
    }
  }

  private getThumbnail(): void {
    const dataNodes: HTMLCollectionOf<Element> = this.getMatchingElements(this.document, ["Binary", "Thumbnail", "Data"], false);
    if (dataNodes && dataNodes.length) {
      const pictureNode: Element = this.getElementByAttribute(this.document, dataNodes, null, "EsriPropertyType", "PictureX", false);
      if (pictureNode) {
        this.thumbnail = pictureNode.textContent;
      }
    } else {
      this.thumbnail = null;
    }
  }

  private getItemInfo(): void {
    this.formGroup.controls["contentStatus"].setValue(this.getNodeValueByPath(["itemInfo", "contentStatus"]));
  }

  private convertXmlStringToDate(inDate: string): Date {
    let outDate: Date = null;

    if (inDate && inDate.length) {
      const dashes: string[] = inDate.split("-");
      const slashes = inDate.split("/");

      let y: number = null;
      let m: number = null;
      let d: number = null;

      if (dashes.length === 3) {
        if (dashes[0].length > 2) {
          y = parseInt(dashes[0]);
          m = parseInt(dashes[1]) - 1;
          d = parseInt(dashes[2]);
        } else {
          y = parseInt(dashes[2]);
          m = parseInt(dashes[1]) - 1;
          d = parseInt(dashes[0]);
        }
      } else if (slashes.length === 3) {
        if (slashes[0].length > 2) {
          y = parseInt(slashes[0]);
          m = parseInt(slashes[1]) - 1;
          d = parseInt(slashes[2]);
        } else {
          y = parseInt(slashes[2]);
          m = parseInt(slashes[1]) - 1;
          d = parseInt(slashes[0]);
        }
      } else if (inDate.length === 8) {
        y = parseInt(inDate.substring(0, 4));
        m = parseInt(inDate.substring(4, 6)) - 1;
        d = parseInt(inDate.substring(6, 8));
      }

      outDate = new Date(y, m, d);
    }

    return outDate;
  }

  private convertDateToXmlString(inDate: Date): string {
    let outDate: string = null;

    if (inDate) {
      outDate = inDate.getFullYear().toString() + "-"
      + String(inDate.getMonth() + 1).padStart(2, "0") + "-"
      + String(inDate.getDate()).padStart(2, "0");
    }

    return outDate;
  }

  private getElement(doc: Document, path: string[]): Element {
    let elems: HTMLCollectionOf<Element> = doc.getElementsByTagName(path[0]);
    if (!elems || !elems.length) {
      doc.documentElement.appendChild(doc.createElement(path[0]));
      elems = doc.getElementsByTagName(path[0]);
    }

    for (let i = 1; i < path.length; i++) {
      const parent: Element = elems[0];
      elems = parent.getElementsByTagName(path[i]);
      if (!elems || !elems.length) {
        parent.appendChild(doc.createElement(path[i]));
        elems = parent.getElementsByTagName(path[i]);
      }
    }

    return ((elems && elems.length) ? elems[0] : null);
  }

  private getElementByNodeValue(doc: Document, path: string[], val: string): Element {
    let elems: HTMLCollectionOf<Element> = doc.getElementsByTagName(path[0]);
    if (!elems || !elems.length) {
      doc.documentElement.appendChild(doc.createElement(path[0]));
      elems = doc.getElementsByTagName(path[0]);
    }

    for (let i = 1; i < path.length; i++) {
      const parent: Element = elems[0];
      elems = parent.getElementsByTagName(path[i]);
      if (!elems || !elems.length) {
        parent.appendChild(doc.createElement(path[i]));
        elems = parent.getElementsByTagName(path[i]);
      }
    }

    let elem: Element = null;
    if (elems && elems.length) {
      for (let i = 0; i < elems.length; i++) {
        let text: string = elems[i].nodeValue;

        if (!(text && text.length)) {
          text = elems[i].textContent;
        }

        if (text === val) {
          elem = elems[i];
          break;
        }
      }
    }

    if (!elem) {
      elem = doc.documentElement.appendChild(doc.createElement(path[0]));
      for (let i = 1; i < path.length; i++) {
        elem = elem.appendChild(doc.createElement(path[i]));
      }
      elem.textContent = val;
    }

    return elem;
  }

  private getMatchingElements(doc: Document, path: string[], create: boolean): HTMLCollectionOf<Element> {
    let elems: HTMLCollectionOf<Element> = null;

    if (path && path.length) {
      elems = doc.getElementsByTagName(path[0]);

      if ((!elems || !elems.length) && create) {
        doc.documentElement.appendChild(doc.createElement(path[0]));
        elems = doc.getElementsByTagName(path[0]);
      }
  
      if (elems && elems.length && (path.length > 1)) {
        for (let i = 1; i < path.length; i++) {
          const parent: Element = elems[0];
          if (parent) {
            elems = parent.getElementsByTagName(path[i]);
            if (!(elems && elems.length) && create) {
              parent.appendChild(doc.createElement(path[i]));
              elems = parent.getElementsByTagName(path[i]);
            }
          }
        }
      }
    }

    return elems;
  }

  private getElementByAttribute(doc: Document, parents: HTMLCollectionOf<Element>, path: string[], name: string, val: string, create: boolean): Element {
    let match: Element = null;

    if (parents && parents.length) {
      for (let i = 0; i < parents.length; i++) {
        let children: HTMLCollectionOf<Element> = null;

        if (path && path.length) {
          children = parents[i].getElementsByTagName(path[0]);
          if (path.length > 1) {
            for (let j = 1; j < path.length; j++) {
              if (children && children.length) {
                children = children[0].getElementsByTagName(path[j]);
              }
            }
          }
        }
  
        let elem: Element = null;
        if (children && children.length) {
          elem = children[0];
        } else {
          elem = parents[i];
        }

        const attr: Attr = elem.attributes.getNamedItem(name);
        if (attr && (attr.value  === val)) {
          match = elem;
          break;
        }
      }

      if (!match && create) {
        if (path && path.length) {
          const fullPath: string[] = [];
          let parent = parents[0];

          while (parent.parentElement) {
            fullPath.push(parent.nodeName);
            parent = parent.parentElement;
          }

          fullPath.reverse();
          fullPath.push.apply(fullPath, path);

          match = parent.appendChild(doc.createElement(fullPath[0]));
          for (let i = 1; i < fullPath.length; i++) {
            match = match.appendChild(doc.createElement(fullPath[i]));
          }
        } else if (!parents[0].hasAttribute(name)) {
          match = parents[0];
        }

        if (match) {
          match.setAttribute(name, val);
        }
      }
    }

    return match;
  }

  private getNodeValueByPath(path: string[]): string {
    return this.getNodeValueByElement(this.getElement(this.document, path));
  }

  private getNodeValueByElement(elem: Element): string {
    let val: string = null;

    if (elem && elem.childNodes && elem.childNodes.length) {
      val = elem.childNodes[0].nodeValue;

      if (!(val && val.length)) {
        val = elem.childNodes[0].textContent;
      }
    }

    return val;
  }

  private getChildNode(doc: Document, parentElem: Element, childName: string, create: boolean): Element {
    let node: Element = null;
    let nodes: HTMLCollectionOf<Element> = parentElem.getElementsByTagName(childName);

    if (doc && create && (!nodes || !nodes.length)) {
      node = doc.createElement(childName);
      parentElem.appendChild(node);
    } else {
      node = nodes[0];
    }

    return node;
  }

  private getChildNodeValue(parentElem: Element, childName: string): string {
    const childNode: Element = this.getChildNode(this.document, parentElem, childName, false);
    return (childNode ? this.getNodeValueByElement(childNode) : null);
  }

  private getAttributeValueByPath(path: string[]): string {
    return this.getAttributeValueByElement(this.getElement(this.document, path));
  }

  private getAttributeValueByElement(elem: Element): string {
    let val: string = null;
    if (elem && elem.attributes) {
      const attr: Attr = elem.attributes.getNamedItem("value");
      if (attr) {
        val = attr.value;
      }
    }
    return val;
  }

  private getInnerHtml(elem: Element, tag: string): string {
    const nodes: HTMLCollectionOf<Element> = elem.getElementsByTagName(tag);
    return ((nodes && nodes.length) ? nodes[0].innerHTML : null);
  }

  private setNodeValue(doc: Document, path: string[], val: string): void {
    let elems: HTMLCollectionOf<Element> = doc.getElementsByTagName(path[0]);
    if (!elems || !elems.length) {
      doc.documentElement.appendChild(doc.createElement(path[0]));
      elems = doc.getElementsByTagName(path[0]);
    }

    for (let i = 1; i < path.length; i++) {
      const parent: Element = elems[0];
      elems = parent.getElementsByTagName(path[i]);
      if (!elems || !elems.length) {
        parent.appendChild(doc.createElement(path[i]));
        elems = parent.getElementsByTagName(path[i]);
      }
    }

    if (!elems[0].childNodes || !elems[0].childNodes.length) {
      elems[0].appendChild(doc.createTextNode(val ? val : ""));
    } else {
      elems[0].childNodes[0].textContent = (val ? val : "");
    }
  }

  private setChildValue(doc: Document, parent: Element, path: string[], val: string): void {
    let elems: HTMLCollectionOf<Element> = null;

    for (let i = 0; i < path.length; i++) {
      elems = parent.getElementsByTagName(path[i]);

      if (!elems || !elems.length) {
        parent.appendChild(doc.createElement(path[i]));
        elems = parent.getElementsByTagName(path[i]);
      }

      parent = elems[0];
    }

    if (elems && elems.length) {
      if (!elems[0].childNodes || !elems[0].childNodes.length) {
        elems[0].appendChild(doc.createTextNode(val ? val : ""));
      } else {
        elems[0].childNodes[0].textContent = (val ? val : "");
      }
    }
  }

  private setAttribute(doc: Document, path: string[], name: string, val: string): void {
    let elems: HTMLCollectionOf<Element> = doc.getElementsByTagName(path[0]);

    if (!elems || !elems.length) {
      doc.documentElement.appendChild(doc.createElement(path[0]));
      elems = doc.getElementsByTagName(path[0]);
    }

    let parent: Element = elems[0];
    for (let i = 1; i < path.length; i++) {
      elems = parent.getElementsByTagName(path[i]);

      if (!elems || !elems.length) {
        parent.appendChild(doc.createElement(path[i]));
        elems = parent.getElementsByTagName(path[i]);
      } else {
        parent = elems[0];
      }
    }

    if (elems && elems.length && elems[0].attributes) {
      let attr: Attr = elems[0].attributes.getNamedItem(name);
      if (attr) {
        attr.value = (val ? val : "");
        elems[0].attributes.setNamedItem(attr);
      } else {
        elems[0].setAttribute(name, (val ? val : ""));
      }
    }
  }

  private setChildAttribute(doc: Document, parent: Element, path: string[], name: string, val: string): void {
    let node: Element = parent;

    if (path && path.length) {
      let elems: HTMLCollectionOf<Element> = null;

      for (let i = 0; i < path.length; i++) {
        elems = parent.getElementsByTagName(path[i]);
  
        if (!elems || !elems.length) {
          parent.appendChild(doc.createElement(path[i]));
          elems = parent.getElementsByTagName(path[i]);
        }
  
        parent = elems[0];
      }
  
      if (elems && elems.length && elems[0].attributes) {
        node = elems[0];
      }
    }

    if (node && node.attributes) {
      let attr: Attr = node.attributes.getNamedItem(name);
      if (attr) {
        attr.value = (val ? val : "");
        node.attributes.setNamedItem(attr);
      } else {
        node.setAttribute(name, (val ? val : ""));
      }
    }
  }

  private setEsriXmlKeywords(dataIdInfoNode: Element): void {
    const searchKeyNode: Element = this.getChildNode(this.document, dataIdInfoNode, "searchKeys", true);
    searchKeyNode.textContent = "";

    this.keywords.forEach(k => {
      const keywordElem = this.document.createElement("keyword");
      const keywordText = this.document.createTextNode(k.trim());
      keywordElem.appendChild(keywordText);
      searchKeyNode.appendChild(keywordElem);
    });
  }

  private setIsoXmlKeywords(isoDoc: Document, identificationInfoNode: Element): void {
    const md_DataIdentificationNode: Element = this.getChildNode(isoDoc, identificationInfoNode, "MD_DataIdentification", true);
    const descriptiveKeywordsNode: Element = this.getChildNode(isoDoc, md_DataIdentificationNode, "descriptiveKeywords", true);
    const md_KeywordsNode: Element = this.getChildNode(isoDoc, descriptiveKeywordsNode, "MD_Keywords", true);
    const keywordNode: Element = this.getChildNode(isoDoc, md_KeywordsNode, "keyword", true);
    const characterStringNode: Element = this.getChildNode(isoDoc, keywordNode, "gco:CharacterString", true);
    characterStringNode.textContent = this.keywords.join(", ");
  }

  private setEsriXmlThumbnail(): void {
    const create: boolean = ((this.thumbnail && this.thumbnail.length) ? true: false);
    const dataNodes: HTMLCollectionOf<Element> = this.getMatchingElements(this.document, ["Binary", "Thumbnail", "Data"], create);

    if (dataNodes && dataNodes.length) {
      const pictureNode: Element = this.getElementByAttribute(this.document, dataNodes, null, "EsriPropertyType", "PictureX", create);

      if (pictureNode) {
        pictureNode.textContent = this.thumbnail;
      }
    }
  }

}
