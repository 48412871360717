export class Code {
  public key: string;
  public value: string;
}

export const CharacterSetCodes: Code[] = [
  { key: "001", value: "ucs2" },
  { key: "002", value: "ucs4" },
  { key: "003", value: "utf7" },
  { key: "004", value: "utf8" },
  { key: "005", value: "utf16" },
  { key: "006", value: "8859part1" },
  { key: "007", value: "8859part2" },
  { key: "008", value: "8859part3" },
  { key: "009", value: "8859part4" },
  { key: "010", value: "8859part5" },
  { key: "011", value: "8859part6" },
  { key: "012", value: "8859part7" },
  { key: "013", value: "8859part8" },
  { key: "014", value: "8859part9" },
  { key: "015", value: "8859part10" },
  { key: "016", value: "8859part11" },
  { key: "018", value: "8859part13" },
  { key: "019", value: "8859part14" },
  { key: "020", value: "8859part15" },
  { key: "021", value: "8859part16" },
  { key: "022", value: "jis" },
  { key: "023", value: "shiftJIS" },
  { key: "024", value: "eucJP" },
  { key: "025", value: "usAscii" },
  { key: "026", value: "ebcdic" },
  { key: "027", value: "eucKR" },
  { key: "028", value: "big5" },
  { key: "029", value: "GB2312" },
];

export const ScopeCodes: Code[] = [
  { key: "001", value: "attribute" },
  { key: "002", value: "attributeType" },
  { key: "003", value: "collectionHardware" },
  { key: "004", value: "collectionSession" },
  { key: "005", value: "dataset" },
  { key: "006", value: "series" },
  { key: "007", value: "nonGeographicDataset" },
  { key: "008", value: "dimensionGroup" },
  { key: "009", value: "feature" },
  { key: "010", value: "featureType" },
  { key: "011", value: "propertyType" },
  { key: "012", value: "fieldSession" },
  { key: "013", value: "software" },
  { key: "014", value: "service" },
  { key: "015", value: "model" },
  { key: "016", value: "tile" },
  { key: "017", value: "initiative" },
  { key: "018", value: "stereomate" },
  { key: "019", value: "sensor" },
  { key: "020", value: "platformSeries" },
  { key: "021", value: "sensorSeries" },
  { key: "022", value: "productionSeries" },
  { key: "023", value: "transferAggregate" },
  { key: "024", value: "otherAggregate" },
];

export const ProgressCodes: Code[] = [
  { key: "001", value: "completed" },
  { key: "002", value: "historicalArchive" },
  { key: "003", value: "obsolete" },
  { key: "004", value: "onGoing" },
  { key: "005", value: "planned" },
  { key: "006", value: "required" },
  { key: "007", value: "underDevelopment" },
  { key: "008", value: "proposed" },
];

export const SpatialRepresentationTypeCodes: Code[] = [
  { key: "001", value: "vector" },
  { key: "002", value: "grid" },
  { key: "003", value: "textTable" },
  { key: "004", value: "tin" },
  { key: "005", value: "stereoModel" },
  { key: "006", value: "video" },
];

export const TopicCategoryCodes: Code[] = [
  { key: "001", value: "farming" },
  { key: "002", value: "biota" },
  { key: "003", value: "boundaries" },
  { key: "004", value: "climatologyMeteorologyAtmosphere" },
  { key: "005", value: "economy" },
  { key: "006", value: "elevation" },
  { key: "007", value: "environment" },
  { key: "008", value: "geoscientificInformation" },
  { key: "009", value: "health" },
  { key: "010", value: "imageryBaseMapsEarthCover" },
  { key: "011", value: "intelligenceMilitary" },
  { key: "012", value: "inlandWaters" },
  { key: "013", value: "location" },
  { key: "014", value: "oceans" },
  { key: "015", value: "planningCadastre" },
  { key: "016", value: "society" },
  { key: "017", value: "structure" },
  { key: "018", value: "transportation" },
  { key: "019", value: "utilitiesCommunication" },
];

export const AssociationTypeCodes: Code[] = [
  { key: "001", value: "crossReference" },
  { key: "002", value: "largerWorkCitation" },
  { key: "003", value: "partOfSeamlessDatabase" },
  { key: "004", value: "source" },
  { key: "005", value: "stereoMate" },
  { key: "006", value: "isComposedOf" },
];

export const InitiativeTypeCodes: Code[] = [
  { key: "001", value: "campaign" },
  { key: "002", value: "collection" },
  { key: "003", value: "exercise" },
  { key: "004", value: "experiment" },
  { key: "005", value: "investigation" },
  { key: "006", value: "mission" },
  { key: "007", value: "sensor" },
  { key: "008", value: "operation" },
  { key: "009", value: "platform" },
  { key: "010", value: "process" },
  { key: "011", value: "program" },
  { key: "012", value: "project" },
  { key: "013", value: "study" },
  { key: "014", value: "task" },
  { key: "015", value: "trial" },
];

export const RestrictionCodes: Code[] = [
  { key: "001", value: "copyright" },
  { key: "002", value: "patent" },
  { key: "003", value: "patentPending" },
  { key: "004", value: "trademark" },
  { key: "005", value: "license" },
  { key: "006", value: "intellectualPropertyRights" },
  { key: "007", value: "restricted" },
  { key: "008", value: "otherRestrictions" },
  { key: "009", value: "licenseUnrestricted" },
  { key: "010", value: "licenseEndUser" },
  { key: "011", value: "licenseDistributor" },
  { key: "012", value: "privacy" },
  { key: "013", value: "statutory" },
  { key: "014", value: "confidential" },
  { key: "015", value: "sensitivity" },
];

export const ClassificationCodes: Code[] = [
  { key: "001", value: "unclassified" },
  { key: "002", value: "restricted" },
  { key: "003", value: "confidential" },
  { key: "004", value: "secret" },
  { key: "005", value: "topSecret" },
  { key: "006", value: "sensitive" },
  { key: "007", value: "forOfficialUseOnly" },
];

export const MaintenanceFrequencyCodes: Code[] = [
  { key: "001", value: "continual" },
  { key: "002", value: "daily" },
  { key: "003", value: "weekly" },
  { key: "004", value: "fortnightly" },
  { key: "005", value: "monthly" },
  { key: "006", value: "quarterly" },
  { key: "007", value: "biannually" },
  { key: "008", value: "annually" },
  { key: "009", value: "asNeeded" },
  { key: "010", value: "irregular" },
  { key: "011", value: "notPlanned" },
  { key: "012", value: "unknown" },
  { key: "013", value: "semimonthly" },
];

export const TopologyLevelCodes: Code[] = [
  { key: "001", value: "geometryOnly" },
  { key: "002", value: "topology1D" },
  { key: "003", value: "planarGraph" },
  { key: "004", value: "fullPlanarGraph" },
  { key: "005", value: "surfaceGraph" },
  { key: "006", value: "fullSurfaceGraph" },
  { key: "007", value: "topology3D" },
  { key: "008", value: "fullTopology3D" },
  { key: "009", value: "abstract" },
];

export const GeometricObjectTypeCodes: Code[] = [
  { key: "001", value: "complex" },
  { key: "002", value: "composite" },
  { key: "003", value: "curve" },
  { key: "004", value: "point" },
  { key: "005", value: "solid" },
  { key: "006", value: "surface" },
];

export const DataTypeCodes: Code[] = [
  { key: "001", value: "class" },
  { key: "002", value: "codelist" },
  { key: "003", value: "enumeration" },
  { key: "004", value: "codelistElement" },
  { key: "005", value: "abstractClass" },
  { key: "006", value: "aggregateClass" },
  { key: "007", value: "specifiedClass" },
  { key: "008", value: "datatypeClass" },
  { key: "009", value: "interfaceClass" },
  { key: "010", value: "unionClass" },
  { key: "011", value: "metaClass" },
  { key: "012", value: "typeClass" },
  { key: "013", value: "characterString" },
  { key: "014", value: "integer" },
  { key: "015", value: "association" },
];

export const RoleCodes: Code[] = [
  { key: "001", value: "resourceProvider" },
  { key: "002", value: "custodian" },
  { key: "003", value: "owner" },
  { key: "004", value: "user" },
  { key: "005", value: "distributor" },
  { key: "006", value: "originator" },
  { key: "007", value: "pointOfContact" },
  { key: "008", value: "principalInvestigator" },
  { key: "009", value: "processor" },
  { key: "010", value: "publisher" },
  { key: "011", value: "author" },
  { key: "012", value: "collaborator" },
  { key: "013", value: "editor" },
  { key: "014", value: "mediator" },
  { key: "015", value: "rightsHolder" },
];

export const PriorityCodes: Code[] = [
  { key: "001", value: "critical" },
  { key: "002", value: "highImportance" },
  { key: "003", value: "mediumImportance" },
  { key: "004", value: "lowImportance" },
];

export const GeometryTypeCodes: Code[] = [
  { key: "001", value: "point" },
  { key: "002", value: "linear" },
  { key: "003", value: "areal" },
  { key: "004", value: "strip" },
];

export const OperationTypeCodes: Code[] = [
  { key: "001", value: "real" },
  { key: "002", value: "simulated" },
  { key: "003", value: "synthetic" },
];

export const ContentStatusCodes: Code[] = [
  { key: "", value: "" },
  { key: "deprecated", value: "deprecated" },
  { key: "org_authoritative", value: "org_authoritative" },
];
