import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, map } from "rxjs/operators";
import OAuthInfo from "@arcgis/core/identity/OAuthInfo";
import esriId from "@arcgis/core/identity/IdentityManager";
import { environment } from "../../environments/environment";
import { Globals } from "src/app/globals";

@Injectable()
export class AuthenticationService {

  public oauthInfo: OAuthInfo = null;
  public token: string = null;
  public username: string = null;

  public get isLoggedIn(): boolean {
    return ((this.username != null) && (this.username.length > 0));
  }

  private get authUrl(): string {
    let url: string = null;

    if (this.oauthInfo && this.oauthInfo.portalUrl && this.oauthInfo.portalUrl.length) {
      url = this.oauthInfo.portalUrl;
      if (!url.endsWith("/")) {
        url += "/";
      }
      url += "sharing/rest";
    }

    return url;
  }

  constructor(private http: HttpClient, private globals: Globals) {
  }

  public async initialize(): Promise<string> {
    if (this.oauthInfo) {
      return null;
    }

    const endpoint: string = `${environment.proxyUrl}GetPortalSettings?subscription-key=${environment.proxyKey}`;
    return this.http
      .get<any>(endpoint)
      .pipe(map(data => {
        if (!data.oauthUrl || !data.clientId) {
          return "Unable to retrieve portal settings from the proxy service.";
        }

        this.oauthInfo = new OAuthInfo({
          appId: data.clientId,
          portalUrl: `${data.oauthUrl}`,
          popup: false,
        });

        esriId.registerOAuthInfos([this.oauthInfo]);
        return null;
      }),
        catchError(this.globals.handleError)
      )
      .toPromise()
      .catch((err) => {
        return `Error in GetPortalSettings: ${err}`;
      });
  }

  public async login(): Promise<string> {
    return await this.checkSignin(true);
  }

  public async logout(): Promise<string> {
    esriId.destroyCredentials();
    this.initCredentials(null);
    return null;
  }

  public async checkSignin(login: boolean): Promise<string> {
    let result = null;

    if (this.authUrl && this.authUrl.length) {
      await esriId
      .checkSignInStatus(this.authUrl)
      .then((result) => {
        this.initCredentials(result);
        return true;
      })
      .catch((err) => {
        if (login) {
          this.getCredentials().then((creds) => {
            result = creds;
          });
        }
      });
    } else {
      result = "Error in checkSignin: Authorization info has not been initialized.";
    }

    return result;
  }

  private async getCredentials(): Promise<string> {
    let result = null;

    if (this.authUrl && this.authUrl.length) {
      await esriId
      .getCredential(this.authUrl)
      .then((result) => {
        this.initCredentials(result);
      })
      .catch((err) => {
        result = `Error in getCredentials: ${err}`;
      });
    } else{
      result = "Error in getCredentials: Authorization info has not been initialized.";
    }

    return result;
  }

  private initCredentials(creds: __esri.Credential) {
    this.username = creds ? creds.userId : null;
    this.token = creds ? creds.token : null;
  }

}
