<form [formGroup]="metadata.formGroup">
  <fieldset class="k-form-fieldset">
    <div class="row">
      <div class="col-12">
        <div class="row pb-3">
          <div class="col-6">
            <h4>Responsible Party</h4>
          </div>
          <div class="col-6">
            <h4>Metadata Contact</h4>
          </div>
        </div>
        <div class="row pb-3">
          <div class="col-6">
            <kendo-formfield>
              <kendo-label
                [for]="rpStewardName"
                text="Steward"
                class="form-title"
              ></kendo-label>
              <kendo-textbox
                formControlName="rpStewardName"
                [clearButton]="true"
                [style.width.px]="inputWidth"
                [readonly]="!metadata.canEdit"
                #rpStewardName
              ></kendo-textbox>
            </kendo-formfield>
          </div>

          <div class="col-6">
            <kendo-formfield>
              <kendo-label
                [for]="rpStewardName"
                text="Steward"
                class="form-title"
              ></kendo-label>
              <kendo-textbox
                formControlName="rpStewardName"
                [clearButton]="true"
                [style.width.px]="inputWidth"
                [readonly]="!metadata.canEdit"
                #rpStewardName
              ></kendo-textbox>
            </kendo-formfield>
          </div>
        </div>

        <div class="row pb-3">
          <div class="col-6">
            <kendo-formfield>
              <kendo-label
                [for]="rpOrganization"
                text="Organziation"
                class="form-title"
              ></kendo-label>
              <kendo-textbox
                formControlName="rpOrganization"
                [clearButton]="true"
                [style.width.px]="inputWidth"
                [readonly]="!metadata.canEdit"
                #rpOrganization
              ></kendo-textbox>
            </kendo-formfield>
          </div>

          <div class="col-6">
            <kendo-formfield>
              <kendo-label
                [for]="mcOrganization"
                text="Organziation"
                class="form-title"
              ></kendo-label>
              <kendo-textbox
                formControlName="mcOrganization"
                [clearButton]="true"
                [style.width.px]="inputWidth"
                [readonly]="!metadata.canEdit"
                #mcOrganization
              ></kendo-textbox>
            </kendo-formfield>
          </div>
        </div>

        <div class="row pb-3">
          <div class="col-6">
            <kendo-formfield>
              <kendo-label
                [for]="rpPosition"
                text="Position"
                class="form-title"
              ></kendo-label>
              <kendo-textbox
                formControlName="rpPosition"
                [clearButton]="true"
                [style.width.px]="inputWidth"
                [readonly]="!metadata.canEdit"
                #rpPosition
              ></kendo-textbox>
            </kendo-formfield>
          </div>

          <div class="col-6">
            <kendo-formfield>
              <kendo-label
                [for]="mcPosition"
                text="Position"
                class="form-title"
              ></kendo-label>
              <kendo-textbox
                formControlName="mcPosition"
                [clearButton]="true"
                [style.width.px]="inputWidth"
                [readonly]="!metadata.canEdit"
                #mcPosition
              ></kendo-textbox>
            </kendo-formfield>
          </div>
        </div>

        <div class="row pb-3">
          <div class="col-6">
            <kendo-formfield>
              <kendo-label
                [for]="rpStreet"
                text="Street"
                class="form-title"
              ></kendo-label>
              <kendo-textbox
                formControlName="rpStreet"
                [clearButton]="true"
                [style.width.px]="inputWidth"
                [readonly]="!metadata.canEdit"
                #rpStreet
              ></kendo-textbox>
            </kendo-formfield>
          </div>

          <div class="col-6">
            <kendo-formfield>
              <kendo-label
                [for]="mcStreet"
                text="Street"
                class="form-title"
              ></kendo-label>
              <kendo-textbox
                formControlName="mcStreet"
                [clearButton]="true"
                [style.width.px]="inputWidth"
                [readonly]="!metadata.canEdit"
                #mcStreet
              ></kendo-textbox>
            </kendo-formfield>
          </div>
        </div>

        <div class="row pb-3">
          <div class="col-6">
            <kendo-formfield>
              <kendo-label
                [for]="rpCity"
                text="City"
                class="form-title"
              ></kendo-label>
              <kendo-textbox
                formControlName="rpCity"
                [clearButton]="true"
                [style.width.px]="inputWidth"
                [readonly]="!metadata.canEdit"
                #rpCity
              ></kendo-textbox>
            </kendo-formfield>
          </div>

          <div class="col-6">
            <kendo-formfield>
              <kendo-label
                [for]="mcCity"
                text="City"
                class="form-title"
              ></kendo-label>
              <kendo-textbox
                formControlName="mcCity"
                [clearButton]="true"
                [style.width.px]="inputWidth"
                [readonly]="!metadata.canEdit"
                #mcCity
              ></kendo-textbox>
            </kendo-formfield>
          </div>
        </div>

        <div class="row pb-3">
          <div class="col-6">
            <kendo-formfield>
              <kendo-label
                [for]="rpState"
                text="State"
                class="form-title"
              ></kendo-label>
              <kendo-textbox
                formControlName="rpState"
                [clearButton]="true"
                [style.width.px]="inputWidth"
                [readonly]="!metadata.canEdit"
                #rpState
              ></kendo-textbox>
            </kendo-formfield>
          </div>

          <div class="col-6">
            <kendo-formfield>
              <kendo-label
                [for]="mcState"
                text="State"
                class="form-title"
              ></kendo-label>
              <kendo-textbox
                formControlName="mcState"
                [clearButton]="true"
                [style.width.px]="inputWidth"
                [readonly]="!metadata.canEdit"
                #mcState
              ></kendo-textbox>
            </kendo-formfield>
          </div>
        </div>

        <div class="row pb-3">
          <div class="col-6">
            <kendo-formfield>
              <kendo-label
                [for]="rpZip"
                text="Zip"
                class="form-title"
              ></kendo-label>
              <kendo-textbox
                formControlName="rpZip"
                [clearButton]="true"
                [style.width.px]="inputWidth"
                [readonly]="!metadata.canEdit"
                #rpZip
              ></kendo-textbox>
            </kendo-formfield>
          </div>

          <div class="col-6">
            <kendo-formfield>
              <kendo-label
                [for]="mcZip"
                text="Zip"
                class="form-title"
              ></kendo-label>
              <kendo-textbox
                formControlName="mcZip"
                [clearButton]="true"
                [style.width.px]="inputWidth"
                [readonly]="!metadata.canEdit"
                #mcZip
              ></kendo-textbox>
            </kendo-formfield>
          </div>
        </div>

        <div class="row pb-3">
          <div class="col-6">
            <kendo-formfield>
              <kendo-label
                [for]="rpEmail"
                text="Email"
                class="form-title"
              ></kendo-label>
              <kendo-textbox
                formControlName="rpEmail"
                [clearButton]="true"
                [style.width.px]="inputWidth"
                [readonly]="!metadata.canEdit"
                #rpEmail
              ></kendo-textbox>
            </kendo-formfield>
          </div>

          <div class="col-6">
            <kendo-formfield>
              <kendo-label
                [for]="mcEmail"
                text="Email"
                class="form-title"
              ></kendo-label>
              <kendo-textbox
                formControlName="mcEmail"
                [clearButton]="true"
                [style.width.px]="inputWidth"
                [readonly]="!metadata.canEdit"
                #mcEmail
              ></kendo-textbox>
            </kendo-formfield>
          </div>
        </div>

        <div class="row pb-3">
          <div class="col-6">
            <kendo-formfield>
              <kendo-label
                [for]="rpPhone"
                text="Phone"
                class="form-title"
              ></kendo-label>

              <kendo-maskedtextbox
                formControlName="rpPhone"
                [style.width.px]="inputWidth"
                [mask]="phoneMask"
                [readonly]="!metadata.canEdit"
                #rpPhone
              ></kendo-maskedtextbox>
            </kendo-formfield>
          </div>

          <div class="col-6">
            <kendo-formfield>
              <kendo-label
                [for]="mcPhone"
                text="Phone"
                class="form-title"
              ></kendo-label>
              <kendo-maskedtextbox
                formControlName="mcPhone"
                [style.width.px]="inputWidth"
                [mask]="phoneMask"
                [readonly]="!metadata.canEdit"
                #mcPhone
              ></kendo-maskedtextbox>
            </kendo-formfield>
          </div>
        </div>

        <div class="row pb-3">
          <div class="col-6">
            <kendo-formfield>
              <kendo-label
                [for]="rpHours"
                text="Hours"
                class="form-title"
              ></kendo-label>
              <kendo-textbox
                formControlName="rpHours"
                [clearButton]="true"
                [style.width.px]="inputWidth"
                [readonly]="!metadata.canEdit"
                #rpHours
              ></kendo-textbox>
            </kendo-formfield>
          </div>

          <div class="col-6">
            <kendo-formfield>
              <kendo-label
                [for]="mcHours"
                text="Hours"
                class="form-title"
              ></kendo-label>
              <kendo-textbox
                formControlName="mcHours"
                [clearButton]="true"
                [style.width.px]="inputWidth"
                [readonly]="!metadata.canEdit"
                #mcHours
              ></kendo-textbox>
            </kendo-formfield>
          </div>
        </div>
      </div>
    </div>
  </fieldset>
</form>
