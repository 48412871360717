import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AppService } from "src/app/services/app.service";

@Component({
  selector: "app-manager",
  templateUrl: "./manager.component.html",
  styleUrls: ["./manager.component.scss"]
})
export class ManagerComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    public appService: AppService,
  ) {
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.appService.id = params["id"];
      this.appService.type = params["type"];
      this.appService.source = params["source"];

      let view: string = null;
      if ((this.appService.id && this.appService.id.length) || (this.appService.type && this.appService.type.length) || (this.appService.source && this.appService.source.length)) {
        view = "details";
      } else {
        view = "catalog";
      }

      this.appService.toggleView(view);
    });
  }

}
