<div class="row">
  <div class="col-12 mt-2 mb-1">
    <kendo-label text="Keywords" class="form-title"></kendo-label>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <kendo-chip
      *ngFor="let keyword of metadata.keywords"
      [label]="keyword"
      style="margin-right: 5px"
      [removable]="metadata.canEdit"
      (remove)="onRemove($event)"
    >
    </kendo-chip>
  </div>
</div>
<div class="row">
 
  <div *ngIf="metadata.canEdit" class="col-12">
    <h6>Available keywords</h6>
    <kendo-dropdownlist
      style="width: 400px"
      [data]="data"
      [filterable]="true"
      textField="text"
      valueField="value"
      (filterChange)="handleFilter($event)"
      (valueChange)="valueChange($event)"
     
    >
      <ng-template kendoDropDownListNoDataTemplate>
        <div>
          No data found.
          <ng-container *ngIf="filter"
            >Do you want to add keyword - "{{ filter }}"? Once record is saved,
            the new keyword will be added to the tag list.</ng-container
          >
          <br />
          <button *ngIf="filter" kendoButton (click)="addNew()">
            Add keyword
          </button>
        </div>
      </ng-template>
    </kendo-dropdownlist>
   
  </div>
</div>
