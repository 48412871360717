<body class="d-flex flex-column">
  <header>
    <div class="header" role="banner">

      <div class="header-icon">
        <a href="/metadata">
          <img
            src="./assets/metadata-icon.svg"
            style="width: 40px; height: 40px"
          />
        </a>
      </div>

      <div class="header-title">
        <a href="/">
          <h1>CTDOT Metadata Manager</h1>
        </a>
      </div>

      <div class="supporting-documentation">
        <div>
          <a href="assets/CTDOT Business Glossary.dot" target="_blank">Business Glossary</a>
        </div>
        <div>
          <a href="assets/Metadata form guidelines.pdf" target="_blank">Metadata Form Guidelines</a>
        </div>
      </div>

      <div class="filler"></div>

      <div>
        <div *ngIf="authenticationService.isLoggedIn">
          {{ authenticationService.username }}
        </div>
        <button class="login-button" (click)="toggleLogin()">
          {{ authenticationService.isLoggedIn ? "Log Out" : "Log In" }}
        </button>
      </div>

    </div>
  </header>

  <main class="container-fluid flex-fill">
    <app-loading-spinner></app-loading-spinner>
    <router-outlet></router-outlet>

    <kendo-dialog title="Error" *ngIf="showError" (close)="closeError()">
      <p>{{ errorMessage }}</p>
      <kendo-dialog-actions>
        <button kendoButton themeColor="primary" (click)="closeError()">
          OK
        </button>
      </kendo-dialog-actions>
    </kendo-dialog>
  </main>

  <footer>
    <div class="footer">
      <div class="row" style="margin-top:0">
        <div class="col-4 text-start float-start" style="padding-top: 5px">
         Copyright &copy; {{ year }} Connecticut Department of Transportation
        </div>

        <div class="col-4 text-center">
          <h6> {{ statusMessage }}</h6>
        </div>

        <div *ngIf="appService.showCatalog && !catalogService.isLoading" class="col-4 text-end float-end">

          <button
            *ngIf="showExport"
            kendoButton
            fillMode="outline"
            style="color: white; margin-left: 20px"
            (click)="onExport()"
          >
            Export
          </button>

          <button
            *ngIf="showReload"
            kendoButton
            fillMode="outline"
            style="color: white; margin-left: 20px"
            (click)="onReload()"
          >
            Reload
          </button>

        </div>
      </div>
    </div>
  </footer>
</body>
